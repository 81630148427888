import Vue from "vue";
import { Toast } from 'vant';
export function getbelongSubAreas(communityId, success) {
  //debugger
Vue.prototype.$http({
  url: Vue.prototype.$http.adornUrl('/wxapp/sys/org/subAreaList'),
  method: 'post',
  params: Vue.prototype.$http.adornParams({communityId},false)
}).then(({data})=> {
    //debugger
  if (data.code == 0) {
    for (let i in data.subAreaList) {
      let subArea = data.subAreaList[i];
      subArea["label"] = subArea.name;
      subArea["value"] = subArea.id + "";
    }
      success(data.subAreaList);
  }else {
      success(data)
  }
},(err)=> {success(err)})
}
/**
 *
 * @param {*} id 车辆id
 */
export function getCarMobile(id,success) {
  Vue.prototype.$http({
      url: Vue.prototype.$http.adornUrl('/wxapp/devicecar/getRealMobile'),
      method: 'GET',
      params: Vue.prototype.$http.adornParams({
          id
      })
  }).then(({data}) => {
      success(data)
  },err => success(err))
}
// 获得小区列表
export function getbelongSubArea(success) {
  //debugger
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/sys/org/subAreaList'),
    method: 'post',
    params: Vue.prototype.$http.adornParams({ communityId: Vue.prototype.$orgId }, false)
  }).then(({ data }) => {
    //debugger
    if (data.code == 0) {
      for (let i in data.subAreaList) {
        let subArea = data.subAreaList[i];
        subArea["label"] = subArea.name;
        subArea["value"] = subArea.id + "";
      }
      success(data.subAreaList);
    } else {
      success(data)
    }
  }, (err) => { success(err) })
}
// 异步获取房屋级联
export function getHouseList (id, depth, success) {
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
    method: 'get',
    params: Vue.prototype.$http.adornParams({ id, depth }, false)
  }).then(({ data }) => {
    //debugger
    if (data.code == 0) {
      success(data.liveWhichTree)
    } else {
      Toast.fail(data.msg)
    }
  })
}
/**
 *获取字典树型结构,即可以查询非顶级字典
 * @param data -{code:"code值",orgId:"字典管理者orgId值,默认为0"}
 * @param success -回调
 */
export function getDictTree(data, success) {
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
    method: 'post',
    params: Vue.prototype.$http.adornParams({ ...data }, false)
  }).then(({ data }) => {
    if (data.code == 0) {
      for (let i in data.dicts) {
        let dict = data.dicts[i];
        dict["label"] = dict.label;
        dict["value"] = dict.value + "";
      }
      success(data.dicts);
    }
  })
}
/**
 * 获取省市区
 */
export function getAreaList(orgId, depth, success) {
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/application/jmgg/appToken'),
    method: 'get',
    params: Vue.prototype.$http.adornParams({
      appId: Vue.prototype.$appId || 'vaccReturn',
      orgId: Vue.prototype.$orgId
    })
  }).then(({ data }) => {
    if (data.code == 0) {
      Vue.prototype.$httpApp({
        url: Vue.prototype.$httpApp.adornUrl('/app/application/getLoginUser'),
        method: 'post',
        params: Vue.prototype.$httpApp.adornParams({ appToken: data.appToken, appId: Vue.prototype.$appId || 'vaccReturn' }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          Vue.$cookies.set('appToken', data.appToken)
          Vue.prototype.$httpApp({
            url: Vue.prototype.$httpApp.adornUrl('/wxapp/sys/org/getTianArea'),
            method: 'post',
            params: Vue.prototype.$httpApp.adornParams({ orgId, depth }, false)
          }).then(({ data }) => {
            if (data.code == 0) {
              success(data.children);
            }
          })
        }
      })
    }
  })
}
/**
 *文件上传
 * @param file -图片
 * @param path -文件路径
 * @param fileName -文件名，可不传，不传为uuid
 * @param success -回调
 */
export function upload(file, path, fileName, success) {
  let formdata = new FormData();
  formdata.append("file", file);
  formdata.append("path", path);
  if (fileName != null) {
    formdata.append("fileName", fileName);
  }
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/file/upload'),
    method: 'post',
    data: formdata
  }).then(({ data }) => {
    success(data)
  }, err => success(err))
}


/**
 * 街道子系统登录
 * @param {*} homePageVO
 * @param {*} topToken
 * @param {*} code
 */
export function twoDomainLogin(homePageVO, topToken, code) {
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl(`/wxapp/getTwoDomainLogin`),
    method: 'get',
    data: Vue.prototype.$http.adornData({
      'homePageVO': homePageVO,
      'topToken': topToken
    })
  }).then(({ data }) => {
    if (data.code == 0) {

      for (let i in data.homePageVO) {
        let value = data.homePageVO[i]
        if (!value) {
          value = ""
        }
      }

      // 如果该方法执行成功则接收返回值
      console.log("login success")
      Vue.prototype.$globalData.token = data.token
      Vue.prototype.$globalData.userInfo = data.homePageVO
      Vue.prototype.$orgId == data.homePageVO.orgId
      Vue.prototype.$router.push('/home')
    } else {
      console.log("login fail")
      // 如果请求失败,则表示没有openId , 需要跳转到登录页面
      Toast.fail(data.msg);
      Vue.prototype.$router.push({ path: '/login', query: { code: code } })
    }
  })
}

/**
 * 获取用户，社区有权限的标签列表
 * @param {} success
 * @param orgId
 * @param assistId
 */
export function listComRightLabel(success,orgId,assistId) {
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl(`/wxapp/user/label/jmgg/treeList`),
    method: 'get',
    params: Vue.prototype.$http.adornParams({
      orgId: Vue.prototype.$orgId||orgId,
      userAssistId: Vue.prototype.$globalData.userInfo.assistId||assistId, //协管员id
      belong: 2,
    })
  }).then(({ data }) => {
    if (data.code == 0) {
      data.labels.map((e) => {
        let parameters = e.parameters
        if (parameters) {
          e["disabled"] = !parameters.rightUpdate
        }
      })
      success(data.labels);
    } else {
      Toast.fail("获取标签列表失败");
      // app.showFailToast("获取标签列表失败", 1500)
    }
  })
}

/**
 *获取虚拟字典list，
 *@param code -
 *  catType-座驾类型，useType-房屋属性，relationships-与户主关系，
 *  solveStatus-受理状态，placeHouseType-场所房屋类型，
 *  registryType-户籍性质，petitionLevel-上访等级
 * @param success -回调
 */
export function getVirtualDict(code, success) {

  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl(`/wxapp/sys/dict/virtual`),
    method: 'get',
    params: Vue.prototype.$http.adornParams({
      code: code
    })
  }).then(({ data }) => {
    if (data.code == 0) {
      data.virtualDicts.map((v) => v.value = v.value + "")
      success(data.virtualDicts);
    } else {
      Toast.fail("获取" + code + "失败");
    }
  })
}

// 获取url中的参数
export function getUrlParameter(url) {
  let query = ''
  if (url.split('?')[1]) {
    query = url.split('?')[1]
  }
  let vars = query.split("&");
  let obj = {}
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    obj[pair[0]] = pair[1]
  }
  return obj
}

export function uploadImg(file, path, success) {
  let formdata = new FormData();
  formdata.append("file", file);
  formdata.append("path", path);
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/file/upload'),
    method: 'post',
    data: formdata
  }).then(({ data }) => {
    success(data)
  }, err => success(err))
}

export function getRealMobile(id,success) {
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/user/getRealMobile'),
    method: 'GET',
    params: Vue.prototype.$http.adornParams({
      id
    })
  }).then(({data}) => {
    success(data)
  },err => success(err))
}

export function getBelongCommunity(orgId, success) {
  //debugger
  Vue.prototype.$http({
      url: Vue.prototype.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
      method: 'get',
      params: Vue.prototype.$http.adornParams({
          id: orgId,
          depth: -1
      },false)
  }).then(({data})=> {
      //debugger
      if (data.code == 0) {
          success(data.liveWhichTree);
      }else {
          success(data)
      }
  },(err)=> {success(err)})
}

export function getbelongGrid(data,success) {
  if(data==null){
      data={orgId:Vue.prototype.$orgId}
  }
Vue.prototype.$http({
  url: Vue.prototype.$http.adornUrl('/wxapp/grid/info/listByUser'),
  method: 'post',
  params: Vue.prototype.$http.adornParams({community:data.orgId},false)
}).then(({data})=> {
  if (data.code == 0) {
    for (let i in data.grids) {
      let grid = data.grids[i];
      grid["label"] = grid.name;
      grid["value"] = grid.id + "";
    }
    success(data.grids);
  }else {
    success(data)
  }
},(err)=> {success(err)})
}

export function getMiniGrid(data, success) {
  Vue.prototype.$http({
      url: Vue.prototype.$http.adornUrl('/wxapp/grid/info/jmggGridAndMini'),
      method: 'post',
      params: Vue.prototype.$http.adornParams({community:Vue.prototype.$orgId,loginUserId:Vue.prototype.$globalData.userInfo.userId,userAssistId: Vue.prototype.$globalData.userInfo.assistId},false)
  }).then(({data})=> {
      if (data.code == 0) {
          success(data.treeVOS);
      }else {
          success(data)
      }
  },(err)=> {success(err)})
}

export function getAppToken(appCode) {
  Vue.prototype.$http({
      url: Vue.prototype.$http.adornUrl('/wxapp/application/appToken'),
      method: 'GET',
      params: Vue.prototype.$http.adornParams({
          appId: appCode,
      })
  }).then(({data}) => {
      if(data && data.code == 0) {
          Vue.prototype.$httpApp({
              url: Vue.prototype.$httpApp.adornUrl('/auth/getLoginUser'),
              method: 'GET',
              params: Vue.prototype.$httpApp.adornParams({
                  appId: appCode,
                  appToken: data.appToken
              })
          }).then(({data}) => {
              if(data && data.code === 0) {
                  sessionStorage.setItem('appToken', data.appToken)
              }
          })
      }
  })
}